// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/Users/mars/code/project/qifujia-cms/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/components/loading';

export function getRoutes() {
  const routes = [
  {
    "path": "/login",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Login__index' */'@/pages/Login/index'), loading: LoadingComponent}),
    "title": "登录",
    "exact": true
  },
  {
    "path": "/noAuth",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ErrorPage__noAuth' */'@/pages/ErrorPage/noAuth'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/404",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ErrorPage__404' */'@/pages/ErrorPage/404'), loading: LoadingComponent}),
    "exact": true
  },
  {
    "path": "/replyMedical/device_list",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__DeviceList_replayMedical' */'@/pages/ChannelManage/Manage/DeviceList_replayMedical'), loading: LoadingComponent}),
    "exact": true,
    "title": "设备列表"
  },
  {
    "path": "/replyMedical/channel/device_detail",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__DeviceList_replayMedical__DeviceDetail' */'@/pages/ChannelManage/Manage/DeviceList_replayMedical/DeviceDetail'), loading: LoadingComponent}),
    "exact": true,
    "title": "设备详情"
  },
  {
    "path": "/replyMedical/channel_order",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__DealData_replyMedical' */'@/pages/ChannelManage/DealData_replyMedical'), loading: LoadingComponent}),
    "exact": true,
    "title": "渠道交易数据"
  },
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__basicLayouts' */'@/layouts/basicLayouts'), loading: LoadingComponent}),
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/components/wrappers/auth'), loading: LoadingComponent})],
    "title": "主页",
    "routes": [
      {
        "path": "/part1",
        "exact": true,
        "title": "仪表盘"
      },
      {
        "path": "/part1/dashboard",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Statistics__Dashboard' */'@/pages/Statistics/Dashboard'), loading: LoadingComponent}),
        "exact": true,
        "title": "仪表盘"
      },
      {
        "path": "/part2",
        "exact": true,
        "title": "用户管理"
      },
      {
        "path": "/part2/user",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__User' */'@/pages/UserManage/User'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户信息管理"
      },
      {
        "path": "/part2/adhd",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__ADHDUser' */'@/pages/UserManage/ADHDUser'), loading: LoadingComponent}),
        "exact": true,
        "title": "ADHD用户管理"
      },
      {
        "path": "/part2/studyGroup",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__StudyGroup' */'@/pages/UserManage/StudyGroup'), loading: LoadingComponent}),
        "exact": true,
        "title": "结伴学习用户管理"
      },
      {
        "path": "/part2/studyGroup/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__StudyGroup__details' */'@/pages/UserManage/StudyGroup/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "结伴学习用户详情"
      },
      {
        "path": "/part2/studyGroup/details/focusDate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__StudyGroup__focusDate' */'@/pages/UserManage/StudyGroup/focusDate'), loading: LoadingComponent}),
        "exact": true,
        "title": "ADHD用户行为数据"
      },
      {
        "path": "/part2/adhd/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__ADHDUser__details' */'@/pages/UserManage/ADHDUser/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "ADHD用户详情"
      },
      {
        "path": "/part2/adhd/details/focusDate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__ADHDUser__focusDate' */'@/pages/UserManage/ADHDUser/focusDate'), loading: LoadingComponent}),
        "exact": true,
        "title": "ADHD用户行为数据"
      },
      {
        "path": "/part2/adhd/courseSetting",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__ADHDUser__courseSetting' */'@/pages/UserManage/ADHDUser/courseSetting'), loading: LoadingComponent}),
        "exact": true,
        "title": "课时配置"
      },
      {
        "path": "/part2/workbench",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__statsSale' */'@/pages/UserManage/statsSale'), loading: LoadingComponent}),
        "exact": true,
        "title": "工作台"
      },
      {
        "path": "/part2/focusReport",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__focusReport' */'@/pages/UserManage/focusReport'), loading: LoadingComponent}),
        "exact": true,
        "title": "训练报告"
      },
      {
        "path": "/part2/user/evaluate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__User__evaluate' */'@/pages/UserManage/User/evaluate'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户测评"
      },
      {
        "path": "/part2/focusDate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__FocusDate' */'@/pages/UserManage/FocusDate'), loading: LoadingComponent}),
        "exact": true,
        "title": "专注力用户行为数据"
      },
      {
        "path": "/part2/user/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__User__details' */'@/pages/UserManage/User/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户信息"
      },
      {
        "path": "/part2/userPublic",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__userPublic' */'@/pages/UserManage/userPublic'), loading: LoadingComponent}),
        "exact": true,
        "title": "公海"
      },
      {
        "path": "/part2/userPublic/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__userPublic__sub__detail' */'@/pages/UserManage/userPublic/sub/detail'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户信息"
      },
      {
        "path": "/part2/userFeedback",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__UserFeedback' */'@/pages/UserManage/UserFeedback'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户反馈"
      },
      {
        "path": "/part2/userFeedback/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__UserFeedback__details' */'@/pages/UserManage/UserFeedback/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "反馈详情"
      },
      {
        "path": "/part2/userGoodsShare",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__UserGoodsShare' */'@/pages/UserManage/UserGoodsShare'), loading: LoadingComponent}),
        "exact": true,
        "title": "转发列表"
      },
      {
        "path": "/part2/userGoodsShare/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__UserGoodsShare__details' */'@/pages/UserManage/UserGoodsShare/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "转发详情"
      },
      {
        "path": "/part2/info",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__Info' */'@/pages/UserManage/Info'), loading: LoadingComponent}),
        "exact": true,
        "title": "落地页信息收集"
      },
      {
        "path": "/part2/info/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__Info__details' */'@/pages/UserManage/Info/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "详情"
      },
      {
        "path": "/part2/userCourse",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__UserCourse' */'@/pages/UserManage/UserCourse'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户课程信息"
      },
      {
        "path": "/part2/userCourseAddRecord",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__AddCourse' */'@/pages/UserManage/AddCourse'), loading: LoadingComponent}),
        "exact": true,
        "title": "添加课程信息"
      },
      {
        "path": "/part2/mobileCourse",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__UserManage__MobileCourse' */'@/pages/UserManage/MobileCourse'), loading: LoadingComponent}),
        "exact": true,
        "title": "开课"
      },
      {
        "path": "/part3",
        "exact": true,
        "title": "分销管理"
      },
      {
        "path": "/part3/distributor",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__distributorNew' */'@/pages/Distribution/distributorNew'), loading: LoadingComponent}),
        "exact": true,
        "title": "分销者管理"
      },
      {
        "path": "/part3/distributor_audit",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__audit' */'@/pages/Distribution/audit'), loading: LoadingComponent}),
        "exact": true,
        "title": "分销人员"
      },
      {
        "path": "/part3/poster",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__poster' */'@/pages/Distribution/poster'), loading: LoadingComponent}),
        "exact": true,
        "title": "海报管理"
      },
      {
        "path": "/part3/kefuRule",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__kefuRule' */'@/pages/Distribution/kefuRule'), loading: LoadingComponent}),
        "exact": true,
        "title": "接单规则设置"
      },
      {
        "path": "/part3/channel_question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__channelQuestion' */'@/pages/Distribution/channelQuestion'), loading: LoadingComponent}),
        "exact": true,
        "title": "测评链接管理"
      },
      {
        "path": "/part3/distributorStat",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Distribution__distributorStat' */'@/pages/Distribution/distributorStat'), loading: LoadingComponent}),
        "exact": true,
        "title": "分销统计"
      },
      {
        "path": "/part4",
        "exact": true,
        "title": "内容管理"
      },
      {
        "path": "/part4/course",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Course' */'@/pages/Substance/Course'), loading: LoadingComponent}),
        "exact": true,
        "title": "课程内容管理"
      },
      {
        "path": "/part4/course/courseDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Course__TabCourseDetails' */'@/pages/Substance/Course/TabCourseDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "课程详情"
      },
      {
        "path": "/part4/course/videoDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Course__TabVideoDetails' */'@/pages/Substance/Course/TabVideoDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "视频详情"
      },
      {
        "path": "/part4/course/videoLearnDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Course__TabVideoLearnDetails' */'@/pages/Substance/Course/TabVideoLearnDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "学习力视频详情"
      },
      {
        "path": "/part4/course/audioDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Course__TabAudioDetails' */'@/pages/Substance/Course/TabAudioDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "音频详情"
      },
      {
        "path": "/part4/course/feedbackDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Course__TabFeedbackDetails' */'@/pages/Substance/Course/TabFeedbackDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "视频反馈回复"
      },
      {
        "path": "/part4/professor",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Professor' */'@/pages/Substance/Professor'), loading: LoadingComponent}),
        "exact": true,
        "title": "专家管理"
      },
      {
        "path": "/part4/professor/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Professor__details' */'@/pages/Substance/Professor/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "专家保存/编辑"
      },
      {
        "path": "/part4/question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Question' */'@/pages/Substance/Question'), loading: LoadingComponent}),
        "exact": true,
        "title": "问答内容管理"
      },
      {
        "path": "/part4/question/TabAnswersDetails",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Substance__Question__TabAnswersDetails' */'@/pages/Substance/Question/TabAnswersDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "问答内容新建/编辑"
      },
      {
        "path": "/part5",
        "exact": true,
        "title": "交易管理"
      },
      {
        "path": "/part5/order_patch",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__OrderPatch' */'@/pages/Deal/OrderPatch'), loading: LoadingComponent}),
        "exact": true,
        "title": "补差订单管理"
      },
      {
        "path": "/part5/order92",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Order92' */'@/pages/Deal/Order92'), loading: LoadingComponent}),
        "exact": true,
        "title": "9.9订单统计"
      },
      {
        "path": "/part5/order",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Order' */'@/pages/Deal/Order'), loading: LoadingComponent}),
        "exact": true,
        "title": "订单管理"
      },
      {
        "path": "/part5/order/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Order__details' */'@/pages/Deal/Order/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "订单详情"
      },
      {
        "path": "/part5/goods",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Goods' */'@/pages/Deal/Goods'), loading: LoadingComponent}),
        "exact": true,
        "title": "商品管理"
      },
      {
        "path": "/part5/goods/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Goods__details' */'@/pages/Deal/Goods/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "商品编辑"
      },
      {
        "path": "/part5/userVip",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__UserVip' */'@/pages/Deal/UserVip'), loading: LoadingComponent}),
        "exact": true,
        "title": "会员管理"
      },
      {
        "path": "/part5/vip",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Vip' */'@/pages/Deal/Vip'), loading: LoadingComponent}),
        "exact": true,
        "title": "会员配置"
      },
      {
        "path": "/part5/vip/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Vip__details' */'@/pages/Deal/Vip/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "会员管理"
      },
      {
        "path": "/part5/coupon",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Coupon' */'@/pages/Deal/Coupon'), loading: LoadingComponent}),
        "exact": true,
        "title": "优惠券管理"
      },
      {
        "path": "/part5/coupon/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__Coupon__Details' */'@/pages/Deal/Coupon/Details'), loading: LoadingComponent}),
        "exact": true,
        "title": "优惠券详情"
      },
      {
        "path": "/part5/coupon_project",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Deal__CouponProject' */'@/pages/Deal/CouponProject'), loading: LoadingComponent}),
        "exact": true,
        "title": "优惠券配置"
      },
      {
        "path": "/part6",
        "exact": true,
        "title": "运营管理"
      },
      {
        "path": "/part6/homecourse",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__homecourse' */'@/pages/Operations/homecourse'), loading: LoadingComponent}),
        "exact": true,
        "title": "首页课程管理"
      },
      {
        "path": "/part6/homegoods",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__HomeGoods' */'@/pages/Operations/HomeGoods'), loading: LoadingComponent}),
        "exact": true,
        "title": "首页商品管理"
      },
      {
        "path": "/part6/ads",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__Ads' */'@/pages/Operations/Ads'), loading: LoadingComponent}),
        "exact": true,
        "title": "广告管理"
      },
      {
        "path": "/part6/ads/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__Ads__details' */'@/pages/Operations/Ads/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "广告详情"
      },
      {
        "path": "/part6/questionRecommend",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__QuestionRecommend' */'@/pages/Operations/QuestionRecommend'), loading: LoadingComponent}),
        "exact": true,
        "title": "专家问答池"
      },
      {
        "path": "/part6/evaluate",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__Evaluate' */'@/pages/Operations/Evaluate'), loading: LoadingComponent}),
        "exact": true,
        "title": "测评管理"
      },
      {
        "path": "/part6/distributorFeedback",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__distributorFeedback' */'@/pages/Operations/distributorFeedback'), loading: LoadingComponent}),
        "exact": true,
        "title": "问题管理"
      },
      {
        "path": "/part6/distributorFeedback/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__distributorFeedback__Details' */'@/pages/Operations/distributorFeedback/Details'), loading: LoadingComponent}),
        "exact": true,
        "title": "详情"
      },
      {
        "path": "/part6/issue",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__Issue' */'@/pages/Operations/Issue'), loading: LoadingComponent}),
        "exact": true,
        "title": "常见问题管理"
      },
      {
        "path": "/part6/organ",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Operations__Organ' */'@/pages/Operations/Organ'), loading: LoadingComponent}),
        "exact": true,
        "title": "常见问题管理"
      },
      {
        "path": "/part7",
        "exact": true,
        "title": "提现管理"
      },
      {
        "path": "/part7/distributorCashout",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Withdraw__DistributorCashout' */'@/pages/Withdraw/DistributorCashout'), loading: LoadingComponent}),
        "exact": true,
        "title": "提现申请"
      },
      {
        "path": "/part7/distributorCashout/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Withdraw__DistributorCashout__details' */'@/pages/Withdraw/DistributorCashout/details'), loading: LoadingComponent}),
        "exact": true,
        "title": "申请详情"
      },
      {
        "path": "/part8",
        "exact": true,
        "title": "系统设置"
      },
      {
        "path": "/part8/permission",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Permission' */'@/pages/Setting/Permission'), loading: LoadingComponent}),
        "exact": true,
        "title": "权限管理"
      },
      {
        "path": "/part8/admin",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Setting__Department' */'@/pages/Setting/Department'), loading: LoadingComponent}),
        "exact": true,
        "title": "工种管理"
      },
      {
        "path": "/part9",
        "exact": true,
        "title": "统计管理"
      },
      {
        "path": "/part9/statsOrder",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsOrder' */'@/pages/StatisticalMG/statsOrder'), loading: LoadingComponent}),
        "exact": true,
        "title": "各页面销售统计"
      },
      {
        "path": "/part9/statsUser",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsUser' */'@/pages/StatisticalMG/statsUser'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户曲线"
      },
      {
        "path": "/part9/statsUserPay",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsPay' */'@/pages/StatisticalMG/statsPay'), loading: LoadingComponent}),
        "exact": true,
        "title": "付费比率"
      },
      {
        "path": "/part9/statsInteract",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsInteract' */'@/pages/StatisticalMG/statsInteract'), loading: LoadingComponent}),
        "exact": true,
        "title": "互动分析"
      },
      {
        "path": "/part9/pvuv",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__pvuv' */'@/pages/StatisticalMG/pvuv'), loading: LoadingComponent}),
        "exact": true,
        "title": "pvuv曲线"
      },
      {
        "path": "/part9/platformChannel",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__platformChannel' */'@/pages/StatisticalMG/platformChannel'), loading: LoadingComponent}),
        "exact": true,
        "title": "平台渠道数据统计"
      },
      {
        "path": "/part9/statsH5",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsH5' */'@/pages/StatisticalMG/statsH5'), loading: LoadingComponent}),
        "exact": true,
        "title": "H5统计"
      },
      {
        "path": "/part9/statsApp",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsAPP' */'@/pages/StatisticalMG/statsAPP'), loading: LoadingComponent}),
        "exact": true,
        "title": "H5统计"
      },
      {
        "path": "/part9/statsOrderUser",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__StatisticalMG__statsOrderUser' */'@/pages/StatisticalMG/statsOrderUser'), loading: LoadingComponent}),
        "exact": true,
        "title": "用户支付统计"
      },
      {
        "path": "/part10",
        "exact": true,
        "title": "一体机管理"
      },
      {
        "path": "/part10/aio_device",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Aio__FusioncubeManagement' */'@/pages/Aio/FusioncubeManagement'), loading: LoadingComponent}),
        "exact": true,
        "title": "设备管理"
      },
      {
        "path": "/part10/aio_device/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Aio__FusioncubeManagement__AioDetails' */'@/pages/Aio/FusioncubeManagement/AioDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "设备详情"
      },
      {
        "path": "/part10/aio_hospital",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Aio__HospitalManagement' */'@/pages/Aio/HospitalManagement'), loading: LoadingComponent}),
        "exact": true,
        "title": "医院管理"
      },
      {
        "path": "/part10/aio_hospital/details",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Aio__HospitalManagement__HospitalDetails' */'@/pages/Aio/HospitalManagement/HospitalDetails'), loading: LoadingComponent}),
        "exact": true,
        "title": "医院详情"
      },
      {
        "path": "/part11",
        "exact": true,
        "title": "认知管理"
      },
      {
        "path": "/part11/rz_question",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cognition__Question' */'@/pages/Cognition/Question'), loading: LoadingComponent}),
        "exact": true,
        "title": "认知题库"
      },
      {
        "path": "/part11/rz_course",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cognition__Course' */'@/pages/Cognition/Course'), loading: LoadingComponent}),
        "exact": true,
        "title": "认知课程管理"
      },
      {
        "path": "/part11/rz_question_package",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cognition__CourseHour' */'@/pages/Cognition/CourseHour'), loading: LoadingComponent}),
        "exact": true,
        "title": "认知课时管理"
      },
      {
        "path": "/part11/rz_video",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cognition__Video' */'@/pages/Cognition/Video'), loading: LoadingComponent}),
        "exact": true,
        "title": "认知视频库"
      },
      {
        "path": "/part12/rz_question_package",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__Cognition__CourseHour' */'@/pages/Cognition/CourseHour'), loading: LoadingComponent}),
        "exact": true,
        "title": "认知课时管理"
      },
      {
        "path": "/part12",
        "exact": true,
        "title": "渠道管理"
      },
      {
        "path": "/part12/channel_order",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__DealData' */'@/pages/ChannelManage/DealData'), loading: LoadingComponent}),
        "exact": true,
        "title": "渠道交易数据"
      },
      {
        "path": "/part12/channel",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage' */'@/pages/ChannelManage/Manage'), loading: LoadingComponent}),
        "exact": true,
        "title": "渠道列表"
      },
      {
        "path": "/part12/channel/detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__Details' */'@/pages/ChannelManage/Manage/Details'), loading: LoadingComponent}),
        "exact": true,
        "title": "渠道编辑"
      },
      {
        "path": "/part12/channel/hour",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__Hour' */'@/pages/ChannelManage/Manage/Hour'), loading: LoadingComponent}),
        "exact": true,
        "title": "课时配置"
      },
      {
        "path": "/part12/channel/package",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__Package' */'@/pages/ChannelManage/Manage/Package'), loading: LoadingComponent}),
        "exact": true,
        "title": "套餐配置"
      },
      {
        "path": "/part12/channel/device_list",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__DeviceList' */'@/pages/ChannelManage/Manage/DeviceList'), loading: LoadingComponent}),
        "exact": true,
        "title": "设备列表"
      },
      {
        "path": "/part12/channel/device_detail",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Manage__DeviceList__DeviceDetail' */'@/pages/ChannelManage/Manage/DeviceList/DeviceDetail'), loading: LoadingComponent}),
        "exact": true,
        "title": "设备详情"
      },
      {
        "path": "/part12/doctor_remark",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__Remark' */'@/pages/ChannelManage/Remark'), loading: LoadingComponent}),
        "exact": true,
        "title": "评语管理"
      },
      {
        "path": "/part12/procurement_hour_device",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ChannelManage__TradeCensus' */'@/pages/ChannelManage/TradeCensus'), loading: LoadingComponent}),
        "exact": true,
        "title": "集中采购交易数据统计"
      }
    ]
  },
  {
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__ErrorPage__404' */'@/pages/ErrorPage/404'), loading: LoadingComponent})
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
